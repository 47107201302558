import React, {useEffect, useState} from "react";
import AOS from "aos";


const Servers = () => {
    const [showSecondDetails, setShowSecondDetails] = useState(false);

    useEffect(() => {
        AOS.init({
            duration: 1000
        });
    }, []);

    return (
        <div className={"bg-gradient-to-b from-zinc-900 to-zinc-700 min-h-screen p-4"}>
            <div className={"container mx-auto flex flex-col-reverse lg:flex-row items-center"}>
                <div className={"lg:w-1/2 p-4"}>
                    <h1 className={"text-4xl text-amber-600 font-cubano mb-4"}>Our servers</h1>
                    <p className={"text-lg font-sofia-pro text-gray-50"}>
                        We have been providing the European player-base with Battlefield 4 servers from 2019. On BF4C servers you can expect professional configuration and plugins, but also a very hospitable and charming admin team. We have a fair set of rules on our servers with clear definitions with the addition of the <a href="/banpolicy" className="text-blue-500 border-b-2 border-blue-500 hover:text-blue-600 transition-all">ban policy</a>. Admin actions are being monitored and our admins are exceptionally fast with processing player reports, ban appeals, and other server inquires. Over the years, we have built a strong community around our servers and we are proud of it.
                    </p>
                </div>
                <div className={"lg:w-1/2 p-4"}>
                    <img
                        src={require("../Images/SilkRoadArmy.jpg")}
                        alt={"SilkRoad"}
                        className={"w-full h-auto rounded-lg shadow-lg"}
                    />
                </div>
            </div>

            <div className={"container mx-auto flex flex-col lg:flex-row mt-12 lg:mt-32"}>
                <div data-aos="fade-up" className={"lg:w-1/2 p-4"}>
                    <h1 className={"text-4xl lg:text-5xl text-center sm:text-left text-amber-600 font-cubano"}>Conquest Large All Maps</h1>
                    <p className={"text-lg lg:text-xl text-center sm:text-left font-sofia-pro font-bold text-amber-500 mt-4"}>Where it all started.</p>
                    <p className={"text-base text-center sm:text-left font-sofia-pro text-gray-50 mt-2 mb-4"}>60 Hz tickrate - 64 players - 1000 Tickets - Free DLCs included on weekends</p><br/>
                    <img alt={"server-details"} className={"rounded-lg shadow-xl border-2 border-white cursor-pointer"} src={"https://stats.bf4c.net/common/server-banner/image-banner.php?sid=1"} />

                    <div className={"mt-4 space-x-4"}>
                        <a href={"https://1.bf4c.net"} className={"text-lg lg:text-xl font-attribute-mono font-bold bg-gradient-to-r from-blue-700 via-blue-600 to-blue-500 text-white rounded p-2 mt-4 inline-block transition-transform hover:scale-105"}>Join the server</a>
                        <a href={"https://stats.bf4c.net/index.php?p=home&sid=1"} className={"text-lg lg:text-xl font-attribute-mono font-bold bg-gradient-to-r from-green-700 via-green-600 to-green-500 text-white rounded p-2 mt-4 inline-block transition-transform hover:scale-105"}>Stats</a>
                        <a href={"/vip"} className={"text-lg lg:text-xl font-attribute-mono font-bold bg-gradient-to-r from-yellow-700 via-yellow-600 to-yellow-500 text-white rounded p-2 mt-4 inline-block transition-transform hover:scale-105"}>VIP</a>
                    </div>

                    <h2 className={"text-5xl font-cubano text-cyan-300 text-left mt-12"}>Rules</h2>
                    <div className="border rounded-lg border-gray-100 bg-zinc-800 mt-6 p-4 cursor-pointer">
                        <h3 className={"text-xl font-cubano text-white"}>Rule 1)</h3>
                        <p className={"text-sm font-attribute-mono text-gray-50"}>Avoid making use of glitches and exploits.</p>

                        <h3 className={"text-xl font-cubano text-white"}>Rule 2)</h3>
                        <p className={"text-sm font-attribute-mono text-gray-50"}>Camping in the base or redzones with vehicles or gadgets is not allowed.</p>

                        <h3 className={"text-xl font-cubano text-white"}>Rule 3)</h3>
                        <p className={"text-sm font-attribute-mono text-gray-50"}>Cheating ends in a permanent ban.</p>

                        <h3 className={"text-xl font-cubano text-white"}>Rule 4)</h3>
                        <p className={"text-sm font-attribute-mono text-gray-50"}>Baserape unless you were shot first, airpeace, and ramming with flying vehicles are prohibited.</p>

                        <h3 className={"text-xl font-cubano text-white"}>Rule 5)</h3>
                        <p className={"text-sm font-attribute-mono text-gray-50"}>Hostile behavior (including Racism, Nazism, Sexism, Homophobia, and Toxicity) is not tolerated.</p>

                        <h3 className={"text-xl font-cubano text-white"}>Rule 6)</h3>
                        <p className={"text-sm font-attribute-mono text-gray-50"}>Griefing or trolling your teammates is not allowed.</p>

                        <h3 className={"text-xl font-cubano text-white"}>Rule 7)</h3>
                        <p className={"text-sm font-attribute-mono text-gray-50"}>Driver/pilot chooses gunner(s).</p>

                        <h3 className={"text-xl font-cubano text-white"}>Rule 8)</h3>
                        <p className={"text-sm font-attribute-mono text-gray-50"}>Stream sniping is strictly forbidden.</p>

                        <h3 className={"text-xl font-cubano text-white"}>Rule 9)</h3>
                        <p className={"text-sm font-attribute-mono text-gray-50"}>You cannot steal occupied vehicles from your teammates (even when being repaired).</p>

                        <h3 className={"text-xl font-cubano text-white"}>Rule 10)</h3>
                        <p className={"text-sm font-attribute-mono text-gray-50"}>Stacking or farming resulting in a lower player count is prohibited.</p>

                        <h3 className={"text-xl font-cubano text-white"}>Rule 11)</h3>
                        <p className={"text-sm font-attribute-mono text-gray-50"}>Stealing vehicles from the enemy base is not allowed.</p>

                        <h3 className={"text-xl font-cubano text-white"}>Rule 12)</h3>
                        <p className={"text-sm font-attribute-mono text-gray-50"}>Continuous accusing of another player without evidence or abusing report system with nonsense reasons is punishable.</p>

                        <h3 className={"text-xl font-cubano text-white"}>Rule 13)</h3>
                        <p className={"text-sm font-attribute-mono text-gray-50"}>Spoofing of nickname is strictly not allowed.</p>
                    </div>
                </div>
                <div data-aos="fade-up" className={"lg:w-1/2 p-4"}>
                    <h1 className={"text-4xl lg:text-5xl text-center sm:text-left text-amber-600 font-cubano"}>Mixed
                        Modes</h1>
                    <p className={"text-lg lg:text-xl text-center sm:text-left font-sofia-pro font-bold text-green-500 underline mt-4"}>Our
                        latest addition.</p>
                    <p className={"text-base text-center sm:text-left font-sofia-pro text-gray-50 mt-2 mb-4"}>120 Hz
                        tickrate - 64 players - Tickets differ - Explosives enabled - Infantry Only TDM, Obliteration, CQL and Rush - Selection of DLC maps when filled</p>
                    <img alt={"server-details"} className={"rounded-lg shadow-xl border-2 border-white cursor-pointer"}
                         src={"https://stats.bf4c.net/common/server-banner/image-banner.php?sid=2"}/>

                    <div className={"mt-4 space-x-4"}>
                        <a href={"https://2.bf4c.net"}
                           className={"text-lg lg:text-xl font-attribute-mono font-bold bg-gradient-to-r from-blue-700 via-blue-600 to-blue-500 text-white rounded p-2 mt-4 inline-block transition-transform hover:scale-105"}>Join
                            the server</a>
                        <a href={"https://stats.bf4c.net/index.php?p=home&sid=2"}
                           className={"text-lg lg:text-xl font-attribute-mono font-bold bg-gradient-to-r from-green-700 via-green-600 to-green-500 text-white rounded p-2 mt-4 inline-block transition-transform hover:scale-105"}>Stats</a>
                        <a href={"/vip"}
                           className={"text-lg lg:text-xl font-attribute-mono font-bold bg-gradient-to-r from-yellow-700 via-yellow-600 to-yellow-500 text-white rounded p-2 mt-4 inline-block transition-transform hover:scale-105"}>VIP</a>
                        <button onClick={() => setShowSecondDetails(true)}
                                className={"text-lg lg:text-xl font-attribute-mono font-bold bg-gradient-to-r from-red-700 via-red-600 to-red-500 text-white rounded p-2 mt-4 inline-block transition-transform hover:scale-105"}>Detailed
                            information
                        </button>
                    </div>

                    <h2 className={"text-5xl font-cubano text-cyan-300 text-left mt-12"}>Rules</h2>
                    <div className="border rounded-lg border-gray-100 bg-zinc-800 mt-6 p-4 cursor-pointer">
                        <h3 className={"text-xl font-cubano text-white"}>Rule 1)</h3>
                        <p className={"text-sm font-attribute-mono text-gray-50"}>Avoid making use of glitches and exploits (Movement glitches allowed).</p>

                        <h3 className={"text-xl font-cubano text-white"}>Rule 2)</h3>
                        <p className={"text-sm font-attribute-mono text-gray-50"}>Camping in the base or redzones with
                            vehicles or gadgets is not allowed.</p>

                        <h3 className={"text-xl font-cubano text-white"}>Rule 3)</h3>
                        <p className={"text-sm font-attribute-mono text-gray-50"}>Cheating ends in a permanent ban.</p>

                        <h3 className={"text-xl font-cubano text-white"}>Rule 4)</h3>
                        <p className={"text-sm font-attribute-mono text-gray-50"}>Hostile behavior (including Racism,
                            Nazism, Sexism, Homophobia, and Toxicity) is not tolerated.</p>

                        <h3 className={"text-xl font-cubano text-white"}>Rule 5)</h3>
                        <p className={"text-sm font-attribute-mono text-gray-50"}>Griefing or trolling your teammates is
                            not allowed.</p>

                        <h3 className={"text-xl font-cubano text-white"}>Rule 6)</h3>
                        <p className={"text-sm font-attribute-mono text-gray-50"}>Stream sniping is strictly
                            forbidden.</p>

                        <h3 className={"text-xl font-cubano text-white"}>Rule 7)</h3>
                        <p className={"text-sm font-attribute-mono text-gray-50"}>Stacking or farming resulting in a
                            lower player count is prohibited.</p>

                        <h3 className={"text-xl font-cubano text-white"}>Rule 8)</h3>
                        <p className={"text-sm font-attribute-mono text-gray-50"}>Continuous accusing of another player without evidence or abusing report system with nonsense reasons is punishable.</p>

                        <h3 className={"text-xl font-cubano text-white"}>Rule 9)</h3>
                        <p className={"text-sm font-attribute-mono text-gray-50"}>Spoofing of nickname is strictly not
                            allowed.</p>
                    </div>
                </div>
            </div>
            {showSecondDetails && (
                <div
                    className={"fixed top-0 left-0 w-full h-full flex justify-center items-center bg-opacity-50 bg-zinc-900"}>
                    <div className={"bg-white dark:bg-zinc-800 p-4 sm:p-8 rounded-lg shadow-lg max-w-md flex flex-col"} style={{ maxHeight: "80vh", overflowY: "auto", marginLeft: "5%", marginRight: "5%" }}>
                        <h1 className={"text-4xl text-center font-cubano text-white"}>Detailed information</h1>

                        <h2 className={"text-3xl text-left font-cubano text-red-500 mt-4"}>Explosive limit</h2>
                        <p className={"text-base text-left font-sofia-pro text-white mb-4"}>During the time the round
                            start, players are provided with information about explosive limit being
                            enabled/disabled.</p>
                        <p className={"text-base text-left font-sofia-pro text-white mb-4"}>
                            Players having explosives equipped are told and killed until they unequip them.</p>
                        <h3 className={"text-2xl text-left font-cubano text-emerald-500"}>Banned explosives:</h3>
                        <p className={"text-base text-left font-sofia-pro text-white"}>Frag ammo, AA Mine, SMAW, JAVELIN,
                            IGLA, XM25 Airburst, UCAV, Mortar, SLAM mine, RPG, AT mine, MBT LAW, STRINGER, SRAW, M26 FRAG,
                            M320 HE, M320 LVG, M320 3GL
                        </p>

                        <button onClick={() => setShowSecondDetails(false)} className={"bg-red-500 text-white px-4 py-2 font-bold rounded-lg mt-8 hover:bg-red-600"}>Close</button>
                    </div>
                </div>
            )}

        </div>
    )
}

export default Servers;
