import React from 'react';
import CookieConsent from 'react-cookie-consent';

const CookieBanner = () => {
    return (
        <CookieConsent
            location="bottom"
            cookieName="cookieConsent"
            expires={365}
            buttonText="Accept"
            buttonStyle={{ background: '#2563eb', color: 'white', borderRadius: '5px' }}
            style={{ background: '#27272a', color: 'white' }}
            flipButtons={true}
        >
            We do not store any personal information nor share it with 3rd parties. We only use one cookie to store your cookie consent.
        </CookieConsent>
    );
};

export default CookieBanner;
